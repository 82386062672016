const colors = {
	blue: {
		50: '#e3ecff',
		100: '#b6c6ff',
		200: '#86a1f9',
		300: '#587bf4',
		400: '#2955ef',
		500: '#103bd6',
		600: '#082ea7',
		700: '#042179',
		800: '#00144b',
		900: '#00071f',
	},
	pink: {
		50: '#ffe5ea',
		100: '#fab5c0',
		200: '#f98497',
		300: '#f8556c',
		400: '#f82e44',
		500: '#e01e2b',
		600: '#ae1622',
		700: '#7c0e18',
		800: '#4a050f',
		900: '#1a0004',
	},
	grey: {
		50: '#eef1f9',
		100: '#d3d6df',
		200: '#b6bbc6',
		300: '#9aa0b0',
		400: '#7c849a',
		500: '#626b80',
		600: '#4d5364',
		700: '#373b48',
		800: '#20242c',
		900: '#090c14',
	},
};

export default colors;
