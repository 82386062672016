import { RouteDefinition } from '../../types';
const route: RouteDefinition = {
	path: '/airia-app',
	folder: 'pages',
	componentName: 'airia-app',
	isProtected: false,
	mode: ['development', 'test'],
};

export default route;
