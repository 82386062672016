import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

import App from './App';
import theme from './theme';
import { SolanaWallet } from './wallet/solana-wallets';
import reportWebVitals from './reportWebVitals';
import './styles/index.scss';
import { QuickNodeContextProvider } from './context/quick-node.context';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<BrowserRouter>
		<SolanaWallet>
			<ChakraProvider theme={theme}>
				<QuickNodeContextProvider>
					<App />
				</QuickNodeContextProvider>
			</ChakraProvider>
		</SolanaWallet>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
