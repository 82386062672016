export const UPDATE_AUTHORITY = {
	dev: [
		'BjpyxFNGhXyyrrdG8FdRd7i3U8Tb9V3LqpiPnvJX8g94', // tier 1
		'3xW57gbjUtN87BnMDBLgMT3yrLiBkbBpPDpUZ4B9R8u4', // tier 2
		'2i68BCEGm8yPmttCi2Z7Ge68AsyHMsQuMkwTJS1hf9J6', // tier 3
		'3fbgxcsreFpqGLLw6MoQPx1i9677uZGVPHrnRRKvts7k', // tier 4
		'HvTQwu6hssrB1z6RiNre8DarLGGChG6o7Bjh2tuSd9NX', // tier 5
		'HN6FJMYhhryfug3L4RB6oSvqDLd1y9dfCezpmuzKMANC', // tier 6
		'3eNc7bjn5ewHRctD8jLSX41HAnCHvud4nvQETZdnPTYh', // tier 7
		'4BmDajBYvw24o2A2McCThkNRcMf3pimLDR7u8Frv6JwT', // tier 8
		'7FbDvnPktPdUAYgSh2z7Zbf7LVTMTJouacra54fbRMyM', // tier 9
		'3tvXPAuwVSt8zpKNWP3r1ySDptdkvUCbnZ3yvsfGGw9G', // tier 10
	],
	prod: [
		'9hNfBUaKbXdaT246y8REaUzqgK1bNibV4gLMJGgBRi4g',
		'8ERR2gYrvXcJFuoNAbPRvHXtrJnAXXHgXKkVviwz9R6C',
		'ET3LWbEL6q4aUSjsX5xLyWktCwqKh6qsQE5j6TDZtZBY',
		'EqC1WrQ4xjoAxpe6uvVBDSrPaMH1ru2S6goc17EjfTdG',
		'BBYaQxZMW4ks6UXsR1LCramE2KPZnyTZxQovioqS96Lw',
		'AABYPHu5JmRRR6XCcaUBJeKapEUjD8orS1m8sz5J8giU',
		'8SZLwsxqvRr6g2GGPMZKuiaMfpUWh8pjjXPUwEhr8v27',
		'4SEsAR1tCRkw3ywiinVT1Mynjuhc8pDxHRg6we8H3dPA',
		'3Mky9WFPudSAqtVFkHnzWfMZWEHCWE6RY8oY517cTnxK',
		'Gi6ibj3VtJh7vS82fpKn44arhhaeCKC7iNeJk2SbaA8p',
		'J8BCuQgNXdmvyzYjxLbL4M8J98zgh4fLamPWrnGHXExT', // AiriaLite
	],
};

export const CANDY_MACHINE_ID = {
	dev: [
		'CRV7J3ng7RtKooYhW7jYSb6raADQbV81Vu8684UEHSk4', // tier 1
		'eHPtNJoHmDxCMQQ826JNvPbVysZgDS4yjiDJXpHz7iu', // tier 2
		'36ey91z7V8iFTpvVkZpCkkgtr3g5Ukcq2fPXgi9ycFai', // tier 3
		'FYQDBmQmMLRgbG7KfiCDMWQ1vERsXbomEcSCNaMDgSwS', // tier 4
		'ErStPqwvswbKHntU57VeESnURGsYUmuZb7QheLMqyQUp', // tier 5
		'4N1QuPHRSWcCXVnHWMURAhF4uCSarB7FwfhFs9xmLK1J', // tier 6
		'HgdLYtToVWD7Ev91ot5tmeERaDybTt6j9ZA2U4HrVgrU', // tier 7
		'HdJg6hS4feYXRoFH4qHAVwXtwn684xKimWcBbjiTzmSJ', // tier 8
		'F3DuobxvvK1Ngh58u3hNrcsTyNShYPJ31VcmPyC2ZWcx', // tier 9
		'8xyRyBH64R8Q2cSM5v5ykHrLZ6xsfVhNshKB959mCJNU', // tier 10
	],
	prod: [
		'CNcoLGiY5QiCYqCXo1uAogZuNCjBXKSRTg738PZxWM5p',
		'5GGp28RmF8j2VqsXe1PZfrDoaPQX87GbKBJMJnRp7FGn',
		'47qTLBgqnQQvgPgmRVWPc2Lg1vCDzBe31CDN1DGCjfkk',
		'BojLRoHY2nKLE9V2CZVDCfNhSGVPXH9ZyEsjzXcyWDQq',
		'5CbGrcb1ENxwo5uPtnceCb7KF82Zmu1dcQidEsis3q7a',
		'8q6Q1pMnwTPqtf1uLykieRtTVDYL2Gki666w9ZcjydC5',
		'33vMz9s2M51XYmbMzKn6MH7w4ZWXsWNVhkANnKyqtk5v',
		'HnPCqxUvkqWHmgsmBwNfrpknSNxfmqjhmetatZW6ocVY',
		'6mNuioYGrAmz4x3FrJ1yXnCK5Wa2xHrweSBGehzTbWKb',
		'3bANa75gfkrPNtD4HxskvUb1g8jeFcpLfa6jHhup8Ugi',
		'AJzXbZPHYfbPp16Hc2yLtQEQUd68mWZQXwVpQNXYGQGm',
	],
};

export const NUMBER_OF_TIERS = Number(process.env.REACT_APP_NUMBER_OF_TIERS);
export const pairedPubKeys = {
	dev: UPDATE_AUTHORITY['dev'].map((wallet: string, index) => ({
		wallet,
		creator: CANDY_MACHINE_ID['dev'][index],
	})),
	prod: UPDATE_AUTHORITY['prod'].map((wallet: string, index) => ({
		wallet,
		creator: CANDY_MACHINE_ID['prod'][index],
	})),
};
