import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { AxiosRequestConfig } from 'axios';
import { ManagerOptions, SocketOptions } from 'socket.io-client';
import { chainList } from '../types';
import {
	CANDY_MACHINE_ID,
	UPDATE_AUTHORITY,
	pairedPubKeys,
	NUMBER_OF_TIERS,
} from './moduluc-publickeys';
export type ModeTypes = 'development' | 'production' | 'staging' | 'test';
export interface ApplicationConfig {
	mode: ModeTypes;
	api: Partial<AxiosRequestConfig>;
	solNetwork: {
		network: WalletAdapterNetwork;
		endpoint?: string;
	};
	quickNodeProvide: string;
	socketIO: {
		url: string;
		opts?: Partial<ManagerOptions & SocketOptions>;
	};
	publicKeys: {
		creators: string[];
		updateAuthority: string[];
		paired: Array<{ wallet: string; creator: string }>;
		numberOfTeirs: number;
	};
	ethNetwork: {
		chainId: number;
		network: chainList;
	};
	numberOfTiers: number;
}
const url = process.env.REACT_APP_BASE_URL;
const baseURL = url + '/api';
const dev: ApplicationConfig = {
	mode: 'development',
	api: {
		baseURL,
		headers: {
			'Content-type': 'application/json',
		},
	},
	quickNodeProvide: process.env.REACT_APP_QUICKNODE_HTTP_PROVIDER,
	solNetwork: {
		network:
			(process.env.REACT_APP_SOL_NET as WalletAdapterNetwork) ||
			WalletAdapterNetwork.Devnet,
		endpoint: process.env.REACT_APP_SOL_CONNECTION_ENDPOINT,
	},
	socketIO: {
		url,
		opts: {
			reconnectionDelay: 1000,
			reconnectionAttempts: 3,
			reconnection: true,
		},
	},
	publicKeys: {
		creators: CANDY_MACHINE_ID.dev,
		updateAuthority: UPDATE_AUTHORITY.dev,
		paired: pairedPubKeys.dev,
		numberOfTeirs: NUMBER_OF_TIERS,
	},

	ethNetwork: {
		chainId: Number(process.env.REACT_APP_ETH_CHAINID),
		network: process.env.REACT_APP_ETH_NET as chainList,
	},
	numberOfTiers: NUMBER_OF_TIERS,
};

const prod: ApplicationConfig = {
	mode: 'production',
	api: {
		baseURL,
		headers: { 'Content-type': 'application/json' },
	},
	quickNodeProvide: process.env.REACT_APP_QUICKNODE_HTTP_PROVIDER,
	solNetwork: {
		network:
			(process.env.REACT_APP_SOL_NET as WalletAdapterNetwork) ||
			WalletAdapterNetwork.Mainnet,
		endpoint: process.env.REACT_APP_SOL_CONNECTION_ENDPOINT,
	},
	socketIO: {
		url,
		opts: {
			transports: ['websocket'],
		},
	},
	publicKeys: {
		creators: CANDY_MACHINE_ID.prod,
		updateAuthority: UPDATE_AUTHORITY.prod,
		paired: pairedPubKeys.prod,
		numberOfTeirs: NUMBER_OF_TIERS,
	},

	ethNetwork: {
		chainId: Number(process.env.REACT_APP_ETH_CHAINID),
		network: process.env.REACT_APP_ETH_NET as chainList,
	},
	numberOfTiers: NUMBER_OF_TIERS,
};
const stage: ApplicationConfig = {
	mode: 'staging',
	api: {
		baseURL,
		headers: { 'Content-type': 'application/json' },
	},
	quickNodeProvide: process.env.REACT_APP_QUICKNODE_HTTP_PROVIDER,
	solNetwork: {
		network:
			(process.env.REACT_APP_SOL_NET as WalletAdapterNetwork) ||
			WalletAdapterNetwork.Devnet,
		endpoint: process.env.REACT_APP_SOL_CONNECTION_ENDPOINT,
	},
	socketIO: {
		url,
		opts: {
			transports: ['websocket'],
		},
	},
	publicKeys: {
		creators: CANDY_MACHINE_ID.dev,
		updateAuthority: UPDATE_AUTHORITY.dev,
		paired: pairedPubKeys.dev,
		numberOfTeirs: NUMBER_OF_TIERS,
	},
	ethNetwork: {
		chainId: Number(process.env.REACT_APP_ETH_CHAINID),
		network: process.env.REACT_APP_ETH_NET as chainList,
	},
	numberOfTiers: NUMBER_OF_TIERS,
};

const test: ApplicationConfig = {
	mode: 'test',
	api: {
		baseURL,
		headers: {
			'Content-type': 'application/json',
		},
	},
	quickNodeProvide: process.env.REACT_APP_QUICKNODE_HTTP_PROVIDER,
	solNetwork: {
		network:
			(process.env.REACT_APP_SOL_NET as WalletAdapterNetwork) ||
			WalletAdapterNetwork.Devnet,
		endpoint: process.env.REACT_APP_SOL_CONNECTION_ENDPOINT,
	},
	socketIO: {
		url,
		opts: {
			reconnectionDelay: 1000,
			reconnectionAttempts: 3,
			reconnection: true,
		},
	},
	publicKeys: {
		creators: CANDY_MACHINE_ID.dev,
		updateAuthority: UPDATE_AUTHORITY.dev,
		paired: pairedPubKeys.dev,
		numberOfTeirs: NUMBER_OF_TIERS,
	},

	ethNetwork: {
		chainId: Number(process.env.REACT_APP_ETH_CHAINID),
		network: process.env.REACT_APP_ETH_NET as chainList,
	},
	numberOfTiers: NUMBER_OF_TIERS,
};

const isEnv = (env: ModeTypes) => process.env.REACT_APP_ENV === env;

export const isDev = isEnv('development');
export const isStaging = isEnv('staging');
export const isProd = isEnv('production');
export const isTest = isEnv('test');

export const config: ApplicationConfig = isProd
	? prod
	: isStaging
	? stage
	: isTest
	? test
	: dev;

// export const ArrayOfTiersNumbers = Array.from(
// 	{ length: config.numberOfTiers },
// 	(_, i) => i + 1
// );

export const ArrayOfTiersNumbers = [11,1,2,3,4,5,6,7,8,9,10];
