import { config } from '../config';
import { providers } from 'ethers';
import React from 'react';
interface QuickNodeInterface {
	provider: providers.JsonRpcProvider;
	getNfts: ({ account }: { account: string }) => Promise<any>;
}
const quickNodeDefaultValue: QuickNodeInterface = {
	provider: new providers.JsonRpcProvider(''),
	getNfts: ({ account }) => new Promise(resolve => resolve),
};
export const QuickNodeContext = React.createContext<QuickNodeInterface>(
	quickNodeDefaultValue
);
export const useQuickNode = () => React.useContext(QuickNodeContext);

export const QuickNodeContextProvider: React.FC<{
	children: React.ReactElement | Array<React.ReactElement>;
}> = ({ children }) => {
	const [provider, setProvider] = React.useState(quickNodeDefaultValue.provider);
	React.useEffect(() => {
		setProvider(new providers.JsonRpcProvider(config.quickNodeProvide));
	}, []);
	const getNfts = async ({ account }: { account: string }): Promise<any> => {
		const provider = new providers.JsonRpcProvider(config.quickNodeProvide);
		// provider.connection.headers = { 'x-qn-api-version': 1 };
		return await provider.send('qn_fetchNFTs', {
			// @ts-ignore
			wallet: account,
		});
	};
	const value = React.useMemo<QuickNodeInterface>(
		(): QuickNodeInterface => ({
			provider,
			getNfts,
		}),
		[]
	);
	return (
		<QuickNodeContext.Provider value={value}>
			{children}
		</QuickNodeContext.Provider>
	);
};
