import React from 'react';
import { RouteObject } from 'react-router';
import home from './routes-definitions/home.route';
import notfound from './routes-definitions/notfound.route';
import enviroApp from './routes-definitions/enviro-app.route';
import { Loadable } from './Loadable';
import { RouteDefinition } from '../types';
import { config } from '../config';

const generateRoutes = (routes: RouteDefinition[]): RouteObject[] => {
	return routes
		.filter(route => {
			const { mode } = route;

			if (!mode) return true;
			if (typeof mode === 'string') {
				return config.mode === mode;
			}
			return mode.indexOf(config.mode) >= 0;
		})
		.map(({ path, componentName, folder, children, index }) => ({
			element: <Loadable folder={folder} componentName={componentName} />,
			children: children && generateRoutes(children),
			path,
			index,
		}));
};

const routes = generateRoutes([home, enviroApp, notfound]);
export default routes;
