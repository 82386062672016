import { Box } from '@chakra-ui/react';
import React from 'react';

const Layout = ({
	children,
}: {
	children: React.ReactElement | Array<React.ReactElement>;
}) => (
	<Box minHeight='100vh' background='#0B1418'>
		{children}
	</Box>
);

export default Layout;
