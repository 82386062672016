import React from 'react';
import { useRoutes } from 'react-router-dom';
import appRoutes from './routes';
import Layout from './theme/Layout';

function App() {
	const element = useRoutes(appRoutes);
	if (!element) {
		return null;
	}
	return <Layout>{element}</Layout>;
}

export default App;
